
import { Roles } from "@/api";
import { Permission, Role } from "@/api/types";
import PageTitle from "@/components/common/PageTitle.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { ValidationProvider, ValidationObserver, PageTitle }
})
export default class EditUserRolesPage extends Vue {
  headersTitle = [{ icon: "mdi-account-lock", header: "Edit Role" }];
  buttonsArray = [
    {
      text: "Back to list",
      icon: "",
      color: "gray",
      action: "back"
    }
  ];
  role: Role = {
    name: "",
    id: 0
  };
  permissions: Array<Permission> = [];
  selectedPermissions: Array<number> = [];
  get roleId() {
    return this.$route.params.id;
  }
  async created() {
    try {
      [this.role, this.permissions] = await Promise.all([
        Roles.info(this.$route.params.id),
        Roles.permissions()
      ]);
      this.selectedPermissions = this.role.permissions
        ? this.role.permissions.map((item: Permission) => item.id)
        : [];
    } catch (e) {
      await this.$error(e.errors);
    }
  }
  async save() {
    try {
      await Roles.update(this.roleId, {
        name: this.role.name,
        permissions: this.selectedPermissions
      });
      await this.$success("<strong>Success!</strong> Role has been updated!");
    } catch (e) {
      (this.$refs.roleForm as Vue & {
        setErrors: (errors: any) => void;
      }).setErrors(e.errors);
      await this.$error(e.errors);
    }
  }
}
